import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLazyGetPubModerationsQuery } from "@/redux/slices/publisherApi"
import { Flex, Table, Typography } from "antd"
import { ColumnsType } from "antd/es/table"
import { PubModerationFilters } from "@/components/publisher/Moderation/ModerationFilters"
import { StdPaginationRange } from "@/components/common/Std/Selects/PaginationRange"
import { StdColumnsDropdown } from "@/components/common/Std/Dropdowns/ColumnsDropdown"
import { StdModerationStatusText } from "@/components/common/Std/ModerationStatusText"
import { StdErrorMessage } from "@/components/common/Std/ErrorMessage"
import { DEFAULT_PAGINATION_RANGE } from "@/constants"
import { ModerationStatuses, PublisherModeration } from "@/definitions"
import { useDraggableScroll } from "@/hooks/useDraggableScroll"
import dayjs from "dayjs"

const { Title, Text } = Typography

const TABLE_COLUMNS_TITLES: string[] = [
  "id",
  "last_update_time",
  "ms_name",
  "ps_name",
  "domain",
  "pm_name",
  "price",
  "teaser_name",
  "user_comments",
  "reject_reason",
]

export const PubModeration = (): JSX.Element => {
  const { t } = useTranslation()

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [paginationRange, setPaginationRange] = useState<number>(
    DEFAULT_PAGINATION_RANGE,
  )
  const [selectedColumns, setSelectedColumns] =
    useState<string[]>(TABLE_COLUMNS_TITLES)
  const [widgetId, setWidgetId] = useState<string>("")
  const [statusId, setStatusId] = useState<string>("-1")
  const [startDate, setStartDate] = useState<string>("")
  const [endDate, setEndDate] = useState<string>("")

  const [fetchData, { data, isLoading, isError }] =
    useLazyGetPubModerationsQuery()

  useDraggableScroll(".ant-table-content")

  useEffect(() => {
    fetchData(
      composeQueryParamsString({
        ms_id: statusId,
        from: startDate,
        to: endDate,
        id: widgetId,
      }),
    )
  }, [widgetId, statusId, startDate, endDate])

  const handlePageChange = (page: number): void => {
    setCurrentPage(page)
  }

  const TABLE_COLUMNS: ColumnsType<PublisherModeration> = [
    {
      title: t(`PUB.MODERATION.ID`),
      dataIndex: "id",
      key: "id",
      width: 70,
      sorter: (a, b) => Number(a.id) - Number(b.id),
    },
    {
      title: t(`PUB.MODERATION.LAST_UPDATE_TIME`),
      dataIndex: "last_update_time",
      key: "last_update_time",
      sorter: (a, b) => a.last_update_time - b.last_update_time,
      render: (data: number) => dayjs.unix(data).format("DD.MM.YYYY"),
      defaultSortOrder: "descend",
    },
    {
      title: t(`PUB.MODERATION.MS_NAME`),
      dataIndex: "ms_name",
      key: "ms_name",
      sorter: (a, b) => a.ms_name.localeCompare(b.ms_name),
      render: (status: ModerationStatuses) => (
        <StdModerationStatusText status={status} />
      ),
    },
    {
      title: t(`PUB.MODERATION.PS_NAME`),
      dataIndex: "ps_name",
      key: "ps_name",
      sorter: (a, b) => a.ps_name.localeCompare(b.ps_name),
    },
    {
      title: t(`PUB.MODERATION.DOMAIN`),
      dataIndex: "domain",
      key: "domain",
      sorter: (a, b) => a.domain.localeCompare(b.domain),
    },
    {
      title: t(`PUB.MODERATION.PM_NAME`),
      dataIndex: "pm_name",
      key: "pm_name",
      sorter: (a, b) => a.pm_name.localeCompare(b.pm_name),
    },
    {
      title: t(`PUB.MODERATION.PRICE`) + " / %",
      dataIndex: "price",
      key: "price",
      sorter: (a, b) => Number(a.price) - Number(b.price),
    },
    {
      title: t(`PUB.MODERATION.TEASER_NAME`),
      dataIndex: "teaser_name",
      key: "teaser_name",
      sorter: (a, b) => a.teaser_name.localeCompare(b.teaser_name),
    },
    {
      title: t(`PUB.MODERATION.USER_COMMENTS`),
      dataIndex: "user_comments",
      key: "user_comments",
      width: 200,
      sorter: (a, b) => a.user_comments.localeCompare(b.user_comments),
      render: (value: string) => (
        <span>{value.length === 0 ? "-" : value}</span>
      ),
    },
    {
      title: t(`PUB.MODERATION.REJECT_REASON`),
      dataIndex: "reject_reason",
      key: "reject_reason",
      width: 200,
      sorter: (a, b) => a.reject_reason.localeCompare(b.reject_reason),
      render: (value: string) => (
        <Text>{value.length === 0 ? "-" : value}</Text>
      ),
    },
  ]

  if (isError) {
    return <StdErrorMessage>Error...</StdErrorMessage>
  }

  return (
    <Flex vertical gap="large">
      <Title level={3}>{t("PUB.MODERATION.MODERATION")}</Title>

      <Flex
        style={{ width: "100%" }}
        align="flex-end"
        justify="space-between"
        gap="large"
        wrap="wrap"
      >
        <PubModerationFilters
          t={t}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setStatusId={setStatusId}
          setWidgetId={setWidgetId}
        />
        <Flex gap="large">
          <StdPaginationRange
            t={t}
            paginationRange={paginationRange}
            setPaginationRange={setPaginationRange}
          />
          <StdColumnsDropdown
            t={t}
            namespace="PUB.MODERATION"
            options={TABLE_COLUMNS_TITLES}
            selectedOptions={selectedColumns}
            setSelectedOptions={setSelectedColumns}
          />
        </Flex>
      </Flex>

      <div style={{ position: "relative" }}>
        <Table
          dataSource={data}
          loading={isLoading}
          columns={TABLE_COLUMNS.filter((col: any) =>
            selectedColumns.includes(col?.dataIndex),
          )}
          rowKey="id"
          showSorterTooltip={false}
          sortDirections={["ascend", "descend", "ascend"]}
          scroll={{ x: "max-content" }}
          pagination={{
            position: ["bottomCenter"],
            current: currentPage,
            pageSize: paginationRange,
            showSizeChanger: false,
            onChange: handlePageChange,
            total: data?.length,
            showTotal: (total, range) => {
              if (range[1] > 0) {
                return (
                  <span className="paginationShowTotal">
                    {t("PAGINATION.SHOW_TOTAL", {
                      firstNumber: range[0],
                      secondNumber: range[1],
                      total: total,
                    })}
                  </span>
                )
              }
            },
          }}
        />
      </div>
    </Flex>
  )
}

const composeQueryParamsString = (params: any) => {
  const string = Object.keys(params)
    .filter((key) => !!params[key])
    .map((key) => key + "=" + params[key])
    .join("&")
  return string
}
