import { useEffect, useState } from "react"
import { Typography, Table, Button, Switch, Flex, Input, Select } from "antd"
import { useTranslation } from "react-i18next"
import { StdSpinner } from "@/components/common/Std/Spinner"
import { StdErrorMessage } from "@/components/common/Std/ErrorMessage"
import { StdPaginationRange } from "@/components/common/Std/Selects/PaginationRange"
import { StdColumnsDropdown } from "@/components/common/Std/Dropdowns/ColumnsDropdown"
import { StdLabel } from "@/components/common/Std/Label"
import {
  useDeletePubSourceMutation,
  useEditPubSourceStateMutation,
  useLazyGetPubSourcesQuery,
} from "@/redux/slices/publisherApi"
import { CopyOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { copyToClipboard } from "@/utils/copyToClipboard"
import { DEFAULT_PAGINATION_RANGE } from "@/constants"
import { PubWidgetCreate } from "@/components/publisher/Widgets/WidgetCreate"
import { StdModerationStatusText } from "@/components/common/Std/ModerationStatusText"
import { ColumnsType } from "antd/es/table"
import { ModerationStatuses, PublisherSource } from "@/definitions"
import { NavLink, useNavigate } from "react-router-dom"
import { useGetModerationFiltersQuery } from "@/redux/slices/utilsApi"
import { composeQueryParamsString } from "@/utils/composeQueryParamsString"
import { textToUpperCase } from "@/utils/textToUpperCase"
import { PubWidgetDeleteModal } from "@/components/publisher/Widgets/WidgetDeleteModal"
import styles from "@/components/publisher/Widgets/Widgets.module.css"
import toast from "react-hot-toast"

const { Title } = Typography

const TABLE_COLUMNS_TITLES: string[] = [
  "id",
  "pad",
  "source",
  "payment_model",
  "price",
  "teaser_type",
  "moderation_status",
  "active",
  "copy_code",
  "action",
]

export const PubWidgets = (): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [paginationRange, setPaginationRange] = useState<number>(
    DEFAULT_PAGINATION_RANGE,
  )
  const [selectedColumns, setSelectedColumns] =
    useState<string[]>(TABLE_COLUMNS_TITLES)
  const [widgetId, setWidgetId] = useState<string>("")
  const [statusId, setStatusId] = useState<string>("-1")
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false)
  const [deleteWidgetId, setDeleteWidgetId] = useState<string>("")

  const [fetchData, { data, isLoading, isError }] = useLazyGetPubSourcesQuery()

  const [updateWidgetState] = useEditPubSourceStateMutation()

  const [deleteWidget] = useDeletePubSourceMutation()

  const { data: moderationFiltersData, isLoading: moderationFiltersIsLoading } =
    useGetModerationFiltersQuery()

  useEffect(() => {
    fetchData(composeQueryParamsString({ ms_id: statusId, id: widgetId }))
  }, [widgetId, statusId])

  const handleCopyText = (textToCopy: string): void => {
    copyToClipboard(textToCopy, t("TOASTS.COPIED"))
  }

  const handlePageChange = (page: number): void => {
    setCurrentPage(page)
  }

  const handleOpenDeleteModal = (id: string) => {
    setDeleteWidgetId(id)
    setDeleteModalVisible(true)
  }

  const handleDeleteWidget = async (): Promise<void> => {
    if (!deleteWidgetId) {
      toast.error("Error!")
      return
    }

    try {
      await deleteWidget(deleteWidgetId)
    } catch (error) {
      toast.error("Error!")
    } finally {
      setDeleteModalVisible(false)
      setDeleteWidgetId("")
    }
  }

  if (isLoading) return <StdSpinner />

  if (isError) return <StdErrorMessage>Error...</StdErrorMessage>

  const TABLE_COLUMNS: ColumnsType<PublisherSource> = [
    {
      title: t(`PUB.WIDGETS.ID`),
      dataIndex: "id",
      key: "id",
      width: 70,
      sorter: (a, b) => Number(a.id) - Number(b.id),
    },
    {
      title: t(`PUB.WIDGETS.SOURCE`),
      dataIndex: "source",
      key: "source",
      sorter: (a, b) => a.source.localeCompare(b.source),
      render: (value, record) => (
        <NavLink to={`/publisher/widgets/${record.id}`}>{value}</NavLink>
      ),
    },
    {
      title: t(`PUB.WIDGETS.PAD`),
      dataIndex: "pad",
      key: "pad",
      sorter: (a, b) => a.pad.localeCompare(b.pad),
    },
    {
      title: t(`PUB.WIDGETS.PAYMENT_MODEL`),
      dataIndex: "payment_model",
      key: "payment_model",
      sorter: (a, b) => a.payment_model.localeCompare(b.payment_model),
    },
    {
      title: t(`PUB.WIDGETS.PRICE`) + " / %",
      dataIndex: "price",
      key: "price",
      sorter: (a, b) => Number(a.price) - Number(b.price),
    },
    {
      title: t(`PUB.WIDGETS.TEASER_TYPE`),
      dataIndex: "teaser_type",
      key: "teaser_type",
      sorter: (a, b) => a.teaser_type.localeCompare(b.teaser_type),
    },
    {
      title: t(`PUB.WIDGETS.MODERATION_STATUS`),
      dataIndex: "moderation_status",
      key: "moderation_status",
      sorter: (a, b) => a.moderation_status.localeCompare(b.moderation_status),
      render: (_: any, record: PublisherSource) => (
        <StdModerationStatusText status={record?.moderation_status} />
      ),
    },
    {
      title: <div className={styles.flexItem}>{t(`PUB.WIDGETS.ACTIVE`)}</div>,
      dataIndex: "active",
      key: "active",
      defaultSortOrder: "descend",
      width: 100,
      sorter: (a, b) => a.state.localeCompare(b.state),
      render: (_: any, record: PublisherSource) => (
        <div className={styles.flexItem}>
          <Switch
            checkedChildren={t("BUTTONS.ON")}
            unCheckedChildren={t("BUTTONS.OFF")}
            defaultChecked={record?.state === "on"}
            disabled={record?.moderation_status !== ModerationStatuses.Approved}
            onChange={(checked) =>
              updateWidgetState({
                source_id: record?.id,
                state: checked ? "on" : "off",
              })
            }
          />
        </div>
      ),
    },
    {
      title: (
        <div className={styles.flexItem}>{t(`PUB.WIDGETS.COPY_CODE`)}</div>
      ),
      dataIndex: "copy_code",
      key: "copy_code",
      width: 110,
      render: (_: any, record: PublisherSource) => (
        <div className={styles.flexItem}>
          <Button
            type="primary"
            style={{ padding: "4px 10px" }}
            onClick={() => handleCopyText(record?.code)}
          >
            <CopyOutlined />
          </Button>
        </div>
      ),
    },
    {
      title: <div className={styles.flexItem}>{t(`PUB.WIDGETS.ACTION`)}</div>,
      dataIndex: "action",
      key: "action",
      width: 120,
      render: (_: any, record: PublisherSource) => (
        <div className={styles.flexItem}>
          <Button
            type="primary"
            style={{ padding: "4px 10px" }}
            onClick={() => navigate(`/publisher/widgets/${record.id}`)}
          >
            <EditOutlined />
          </Button>
          <Button
            danger
            style={{ padding: "4px 10px" }}
            type="primary"
            onClick={() => handleOpenDeleteModal(record.id)}
          >
            <DeleteOutlined />
          </Button>
        </div>
      ),
    },
  ]

  return (
    <div style={{ width: "100%" }}>
      <div style={{ width: "100%" }}>
        <Title level={3} style={{ paddingBottom: "5px" }}>
          {t("PUB.WIDGETS.WIDGETS")}
        </Title>
        <PubWidgetCreate t={t} />
        <Flex
          gap="large"
          wrap="wrap"
          style={{ width: "100%", paddingBottom: "24px" }}
          align="flex-end"
          justify="space-between"
        >
          <Flex
            vertical
            gap="large"
            style={{ maxWidth: "300px", width: "100%" }}
          >
            <Title level={4}>{t("PUB.WIDGETS.TABLE_TITLE")}</Title>
            <Input
              placeholder={t("INPUTS.ID_PLACEHOLDER")}
              onChange={(e) => setWidgetId(e.target.value)}
            />
            <StdLabel label={t("SELECTS.STATUS")}>
              <Select
                placeholder={t("SELECTS.STATUS_PLACEHOLDER")}
                defaultValue="-1"
                loading={moderationFiltersIsLoading}
                onChange={(value: string) => setStatusId(value)}
                options={moderationFiltersData?.map((item) => ({
                  label: t(`STATUSES.${textToUpperCase(item.ms_name)}`),
                  value: item.ms_id,
                }))}
              />
            </StdLabel>
          </Flex>
          <Flex gap="large" wrap="wrap">
            <StdPaginationRange
              t={t}
              paginationRange={paginationRange}
              setPaginationRange={setPaginationRange}
            />
            <StdColumnsDropdown
              t={t}
              namespace="PUB.WIDGETS"
              options={TABLE_COLUMNS_TITLES}
              selectedOptions={selectedColumns}
              setSelectedOptions={setSelectedColumns}
            />
          </Flex>
        </Flex>
      </div>

      <PubWidgetDeleteModal
        t={t}
        isOpen={deleteModalVisible}
        setIsOpen={setDeleteModalVisible}
        handleDeleteWidget={handleDeleteWidget}
      />

      <div style={{ position: "relative" }}>
        <Table
          sticky={true}
          scroll={{ x: 107 * selectedColumns?.length }}
          dataSource={data}
          rowKey="id"
          showSorterTooltip={false}
          sortDirections={["ascend", "descend", "ascend"]}
          columns={TABLE_COLUMNS.filter((col: any) =>
            selectedColumns.includes(col?.dataIndex),
          )}
          pagination={{
            position: ["bottomCenter"],
            current: currentPage,
            pageSize: paginationRange,
            showSizeChanger: false,
            onChange: handlePageChange,
            total: data?.length,
            showTotal: (total, range) => {
              if (range[1] > 0) {
                return (
                  <span className="paginationShowTotal">
                    {t("PAGINATION.SHOW_TOTAL", {
                      firstNumber: range[0],
                      secondNumber: range[1],
                      total: total,
                    })}
                  </span>
                )
              }
            },
          }}
        />
      </div>
    </div>
  )
}
