import { useEffect, useState } from "react"
import { PubWidgetEdit } from "@/components/publisher/Widgets/WidgetEdit"
import { PubWidgetDeleteModal } from "@/components/publisher/Widgets/WidgetDeleteModal"
import { StdSpinner } from "@/components/common/Std/Spinner"
import { StdErrorMessage } from "@/components/common/Std/ErrorMessage"
import {
  useDeletePubSourceMutation,
  useGetPubSourceQuery,
} from "@/redux/slices/publisherApi"
import { Breadcrumb, Button, Flex, Input, Typography } from "antd"
import { useTranslation } from "react-i18next"
import { NavLink, useNavigate, useParams } from "react-router-dom"
import { copyToClipboard } from "@/utils/copyToClipboard"
import { ModerationStatuses } from "@/definitions"
import styles from "@/components/publisher/Widgets/Widgets.module.css"
import toast from "react-hot-toast"

const { TextArea } = Input
const { Text } = Typography

export const PubWidget = (): JSX.Element => {
  const { t } = useTranslation()
  const { widgetId } = useParams()
  const navigate = useNavigate()

  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false)

  const { data, isLoading, isError } = useGetPubSourceQuery(widgetId || "")

  const [
    deleteWidget,
    {
      data: deleteWidgetData,
      isSuccess: deleteWidgetIsSuccess,
      isLoading: deleteWidgetIsLoading,
    },
  ] = useDeletePubSourceMutation()

  useEffect(() => {
    if (deleteWidgetIsSuccess && deleteWidgetData?.state === "ok") {
      navigate("/publisher/widgets")
    }
  }, [deleteWidgetData, deleteWidgetIsSuccess])

  if (isLoading) return <StdSpinner />

  if (isError) return <StdErrorMessage>Error...</StdErrorMessage>

  const code = data?.code
  const codeForTextArea = !!code
    ? code
        .replace(/\s*<script/g, "\n<script")
        .replace(/\s*<\/script>/g, "\n</script>")
    : code

  const handleCopyCode = (value: string | undefined): void => {
    if (!value) {
      return
    } else {
      copyToClipboard(value, t("TOASTS.COPIED"))
    }
  }

  const handleDeleteWidget = async (): Promise<void> => {
    if (!widgetId) {
      toast.error("Error!")
      return
    }

    try {
      await deleteWidget(widgetId)
    } catch (error) {
      toast.error("Error!")
    } finally {
      setDeleteModalVisible(false)
    }
  }

  const isApprovedStatus =
    data?.moderation_status === ModerationStatuses.Approved

  return (
    <div>
      <Breadcrumb
        items={[
          {
            title: (
              <NavLink to={"/publisher/widgets"}>
                {t("PUB.WIDGETS.WIDGETS")}
              </NavLink>
            ),
          },
          {
            title: `${t("PUB.WIDGETS.WIDGET_ID")}: ${widgetId || ""}`,
          },
        ]}
      />

      <PubWidgetEdit
        t={t}
        widgetId={widgetId}
        widgetData={data}
        setDeleteModalVisible={setDeleteModalVisible}
        deleteWidgetIsLoading={deleteWidgetIsLoading}
      />

      <PubWidgetDeleteModal
        t={t}
        isOpen={deleteModalVisible}
        setIsOpen={setDeleteModalVisible}
        handleDeleteWidget={handleDeleteWidget}
      />

      <Flex vertical className={styles.textArea}>
        <Text style={{ paddingBottom: "8px" }}>
          {t("PUB.WIDGETS.WIDGET_CODE")}
        </Text>
        <TextArea
          readOnly
          value={codeForTextArea || code}
          rows={7}
          style={{ resize: "none", cursor: "copy" }}
          onClick={() => handleCopyCode(code)}
        />
        <Flex style={{ padding: "24px 0 0 0" }} gap="large" wrap="wrap">
          <Button
            type="primary"
            style={{ flex: "1", width: "100%" }}
            onClick={() => handleCopyCode(code)}
          >
            {t(`PUB.WIDGETS.COPY_CODE`)}
          </Button>

          <Button
            type="primary"
            style={{ flex: "1", width: "100%" }}
            disabled={!isApprovedStatus}
            onClick={() =>
              window.open(
                `https://api.adn.scroogefrog.com/check_banner/?${data?.banner_check_params}`,
              )
            }
          >
            {t("PUB.WIDGETS.TEST_PAGE")}
          </Button>
        </Flex>
      </Flex>
    </div>
  )
}
